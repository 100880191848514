/* ------------------------------ */
/* 顶部和大部分卡片样式 */
.author-content-item .card-content,
.author-content-item {
    border-radius: 12px!important;
    padding: 1rem 1.5rem!important;
}

/* 目录间距 */
#card-toc {
    margin-bottom: 20px;
}

/* 打赏项容器样式 */
.reward-list-item {
  position: relative;
}

.reward-list-item.with-note::after {
  content: attr(data-note);
  position: absolute;
  inset: 0;
  background: var(--anzhiyu-card-bg);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 1;
}

.reward-list-item.with-note:hover::after {
  opacity: 1;
}

.reward-list-item.with-note .reward-list-item-name::after {
  content: '🗨️';
  float: right;
  border-radius: 8px;
  padding: 5px;
  margin: -5px;
  border: var(--style-border-always);
}

/* 小动物夜间显示优化 */
[data-theme='dark'] #page-header.nav-fixed #nav {
    background: var(--anzhiyu-black)!important;
}

[data-theme='dark'] #page-header #nav {
    background: 0 !important;
}

/* 定义自定义鼠标指针样式 */
#cursor {
    position: fixed;
    width: 16px;
    height: 16px;
    background: var(--anzhiyu-fontcolor);
    border-radius: 25px;
    opacity: .3;
    z-index: 10086;
    pointer-events: none;
    transition: .2s ease-in-out;
    transition-property: background, opacity, transform;
}

/* 当鼠标指针不活动时的样式 */
#cursor.hidden {
    opacity: 0;
}

/* 当鼠标指针活动时的样式 */
#cursor.active {
    opacity: .5;
    transform: scale(.5);
}

@media (max-width: 768px) {
    #cursor {
        display: none;
    }
}

::selection {
    color: inherit!important;
}

#aside-content .card-info:hover .author-info-avatar {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
    filter: alpha(opacity = 1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.author-info__description {
    display: none;
}
.card-widget.card-info {
    background: url(https://lib.bsgun.cn/Hexo-static/img/avatar.256.avif) center/cover no-repeat!important;
}

#aside-content >.card-widget.card-info::before {
    background-image: linear-gradient(-25deg, hsl(204deg 100% 50% / 45%), hsl(36.71deg 100% 50% / 45%), hsl(204deg 100% 50% / 45%), hsl(36.71deg 100% 50% / 45%));
    backdrop-filter: blur(5px);
}

#aside-content .card-info:hover .author-status {
    opacity: 1!important;
    transform: scale(1.8) rotate(30deg);
}

/* 隐藏点赞 */
/* 引用链接隐藏外部提示 */
.tk-action .tk-action-link:first-child,
.tag-link-tips {
    display: none!important;
}

/* 手机端搜索栏样式 */
@media screen and (max-width: 768px), (max-height: 580px) {
    .search-dialog {
        top: 2rem!important;
        left: 5%!important;
        width: 90%!important;
        height: auto!important;
        border-radius: 10px!important;
    }
}
#random-post {
    padding: 15px!important;
    min-height: 64px!important;
}

[data-theme="dark"] #random-post {
    background: var(--anzhiyu-card-bg)!important;
}

/* 优化关于界面人生图片 */
img.author-content-img.no-lightbox.entered.loaded {
    filter: blur(0) brightness(.8);
}

/* 滚动条优化 */
#catalog-list,
anzhiyu.barrageContent {
    scrollbar-width: none;
}

/* 优化文章顶部分类和标签显示异常 */
#post-firstinfo .meta-firstline span.post-meta-categories a.post-meta-categories {
    background: rgb(107 107 107 / 50%)!important;
}

#post #post-info #post-firstinfo .meta-firstline .article-meta__tags {
    color: #888!important;
}
/* 链接卡片*/
#article-container .tag-Link .tag-link-bottom {
    margin-top: 0!important;
}

/* 代码块的边框显示优化 */
#article-container figure.highlight {
    border: var(--style-border);
}

/* 手机端nav栏 */
#sidebar #sidebar-menus {
    padding-top: 10px!important;
}

@media screen and (max-width: 768px) {
    /* 在移动端隐藏内容 */
    .card-announcement,
    .card-countdown,
    .card-latest-comments,
    .author-content-item .card-content .banner-button-group {
        display: none !important;
    }
    .post .layout#content-inner {
        background: transparent;
    }
}

/* Twikoo*/
/* 评论框背景图优化 */
.twikoo .el-input__inner,
.twikoo .el-textarea__inner {
    transition: background-position-y 0.3s ease-in-out 0s !important;
}

.twikoo .el-input__inner:focus,
.twikoo .el-textarea__inner:focus {
    background-position-y: 350px;
}

.tk-input .el-textarea__inner {
    -moz-background-size: contain;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain
}

/* 评论回复 */
.tk-action-link {
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    border: var(--style-border);
}

.tk-action-link:hover {
    background: var(--anzhiyu-theme);
    color: #fff;
}

/* 评论后台优化 */
#body-wrap.page .el-input__inner {
    background: #ffffff0f;
}

[data-theme=dark] details > summary {
    background: none!important;
}

/* 一些页面优化 */
body[data-type="fcircle"] #page,
body[data-type="link"] #page,
body[data-type="essay"] #page {
    transition: padding 0s;
}

/* 设置全局鼠标样式 */
html,#bber>section>ul>li>div .bber-info-from, #bber>section>ul>li>div .bber-info-time,#aside-content .card-widget .card-info-social-icons{
    cursor: url(https://cdn.npmmirror.com/packages/pixpro/1.7.6/files/static/images/alternate.png) 16 16, auto!important;
}

a,img, button, input,.title,.nav-button,i.bber-logo,.topGroup .recent-post-item,.category-bar-next,.article-categories-original,#recent-posts .recent-post-item,#recent-posts>.recent-post-item>.recent-post-info,#aside-content .card-widget#card-wechat,.pagination span,.card-author,.card-title,.modal-article-date,summary,#article-container .highlight-tools .copy-button,.el-input__inner,.tk-avatar-img,.tk-comments-title span,.tk-expand,.bber-reply,.reward-button,.share-link .share-button,.comment-barrage-item .barrageContent,.code-expand-btn,.topGroup .banner-button,#aside-content .card-info #author-info__sayhi,.tk-submit-action-icon,.OwO .OwO-body .OwO-bar .OwO-packages li,.OwO .OwO-body .OwO-items .OwO-item,#twikoo .tk-comments .OwO .OwO-body,#rightMenu .rightMenu-group .rightMenu-item,#aside-content .aside-list > .aside-list-item,a span {
    cursor: url(https://cdn.npmmirror.com/packages/pixpro/1.7.6/files/static/images/link.png) 16 16, pointer!important;
}

p, span, h2, label,#toPageText,.local-search-box--input,time,textarea {
    cursor: url(https://cdn.npmmirror.com/packages/pixpro/1.7.6/files/static/images/text.png) 16 16, text!important;
}

/* 夜间切换时全局禁用过渡动画 */
.disable-transitions *:not(.snackbar-container) {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

/* 输入页码 */
#pagination .pagination input {
    background-color: var(--card-bg);
    color: var(--anzhiyu-fontcolor);
}

/* 主页封面 永劫无间 */
.topGroup .todayCard {
    border: var(--style-border);
    border-radius: 12px;
}

/*                  */
/*    友链界面相关   */
/*                  */

/* 友情链接顶部容器动画 */
#flink-banners {
    animation: none!important;
}

/* 友链卡片悬浮效果 */
#article-container .anzhiyu-flink-list .flink-list-item:hover {
    background: var(--anzhiyu-card-bg);
    border: var(--style-border);
    box-shadow: var(--anzhiyu-shadow-border);
}

#article-container .anzhiyu-flink-list .flink-list-item:hover a .flink-item-desc {
    color: var(--anzhiyu-fontcolor);
}

#article-container .anzhiyu-flink-list .flink-list-item:hover a .flink-item-name {
    color: var(--anzhiyu-main);
}

#article-container .anzhiyu-flink-list .flink-list-item .flink-item-info .flink-item-desc {
    font-size: .75rem;
    height: 40px;
}

#article-container .anzhiyu-flink-list .flink-list-item .flink-item-info .flink-item-name {
    line-height: 24px;
    font-size: 18px;
    transition: color .3s ease-in-out;
}

#article-container .anzhiyu-flink-list .flink-list-item:hover a .flink-item-info {
    min-width: 0;
}

#article-container .anzhiyu-flink-list .flink-list-item:hover a img {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    opacity: 1;
    margin: 15px 20px 15px 15px;
}

#article-container .anzhiyu-flink-list .flink-list-item:hover .site-card-tag {
    left: 0;
}

/* 友链卡片背景图片 */
#article-container .anzhiyu-flink-list .flink-list-item {
    position: relative;
    overflow: hidden;
}

#article-container .anzhiyu-flink-list .flink-list-item::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: var(--flink-avatar);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.1;
    transition: opacity 0.3s ease-in-out;
    z-index: 0;
    filter: blur(3px);
}

#article-container .anzhiyu-flink-list .flink-list-item:hover::before {
    opacity: 0.2;
}

/* 友链头像悬浮效果 */
#article-container .anzhiyu-flink-list .flink-list-item a,
.tags-group-icon {
    position: relative;
}

#article-container .anzhiyu-flink-list .flink-list-item a::before,
.tags-group-icon::before {
    content: "→";
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2rem;
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.3s ease-in-out;
    z-index: 1;
}

/* 友链头像 */
#article-container .anzhiyu-flink-list .flink-list-item a::before {
    left: 19px;
    width: 60px;
    height: 60px;
}

.tags-group-icon::before {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    width: 120px;
    height: 120px;
}

#article-container .anzhiyu-flink-list .flink-list-item:hover a::before {
    opacity: 1;
    transform: scale(1);
}

.tags-group-icon:hover::before {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}